.wrapper_card {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  align-items: center;
}

.container_card {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, minmax(10rem, 1fr));
  grid-gap: 25px;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
  will-change: width, height;
}

