@media only screen and (min-width: 821px), (max-width: 1089px) {
  .exp_ctr {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
  }
}

/* small */
@media only screen and (max-width: 549px) {
}
