@media only screen and (min-width: 821px), (max-width: 1089px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image_ctr {
    width: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image {
    max-width: 100%;
    max-height: 100%;
  }
}

/* small */
@media only screen and (max-width: 549px) {
  .social_media_ctr {
    display: flex;
    gap: 2rem;
    padding-top: 2rem;
  }
}
