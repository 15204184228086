@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

li {
  list-style-type: none;
}

html {
  scroll-behavior: smooth;
}