@media only screen and (min-width: 821px), (max-width: 1089px) {
  .home_ctr {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding-top: 4rem;
    width: 100%;
  }

  .home_desc_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
  }

  .wrapper_img {
    display: flex;
    justify-content: flex-end;
  }

  .image_home_ctr {
    width: 20vh;
    border-radius: 50%;
  }

  .image_home {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
  }

  .home_btn_ctr {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  @keyframes typing {
    from {
      width: 0;
    }
  }

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }

  .wrapper_home {
    .item_home {
      visibility: hidden;
      display: none;
    }

    > div {
      font-family: monospace;
      font-size: 4em;
      min-height: 5rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      display: flex;
      align-items: flex-end;
      font-weight: bold;
      color: #3fbf04;

      &:after {
        content: "";
        width: 10px;
        height: 4px;
        display: block;
        background: white;
        color: #3fbf04;
        margin-bottom: 4px;
        margin-left: 2px;
        animation-duration: 350ms;
        animation-name: fade;
        animation-direction: alternate;
        animation-iteration-count: infinite;
      }
    }
  }

  @keyframes fade {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
}

/* small */
@media only screen and (max-width: 549px) {
  .home_ctr {
    gap: 1rem;
  }

  .wrapper_img {
    display: flex;
    justify-content: center;
  }

  .home_btn_ctr {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-top: 2rem;
  }

  .wrapper_home {
    > div {
      font-size: 2em;
      min-height: 3rem;
    }
  }
}
